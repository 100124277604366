import React from "react";
import { sliceTypes } from "../../constants";
import LogosSlice from "./LogosSlice";
import CTABannerSlice from "./CTABannerSlice";
import HighlightsWithImagesSlice from "./HighlightsWithImagesSlice";
import ListsInGridSlice from "./ListsInGridSlice";
import QuotesSlice from "./QuotesSlice";
import HighlightsSlice from "./HighlightsSlice";
import TitleAndParagraphSlice from "./TitleAndParagraphSlice";
import SliderSlice from "./SliderSlice";
import SliderWithBackgroundSlice from "./SliderWithBackgroundSlice";
import VideoAndQuoteSlice from "./VideoAndQuoteSlice";
import CTABannerWithImageSlice from "./CTABannerWithImageSlice";
import StepsSlice from "./StepsSlice";
import FeaturesSlice from "./FeaturesSlice";
import FeaturesInGridSlice from "./FeaturesInGridSlice";
import RichTextFieldSlice from "./RichTextFieldSlice";
import RichTextFieldGridSlice from "./RichTextFieldGridSlice";
import BlogCallOutSlice from "./BlogCallOutSlice";
import BlogCTABannerSlice from "./BlogCTABannerSlice";
import BlogQuoteCallOutSlice from "./BlogQuoteCallOutSlice";
import BlogQuoteEmbeddedSlice from "./BlogQuoteEmbeddedSlice";

const Slices = ({ slices }) => {
  // Render the right markup for a given slice type
  return slices.map((slice, index) => {
    switch (slice.slice_type) {
      case sliceTypes.HIGHLIGHTS:
        return <HighlightsSlice key={index} slice={slice} />;
      case sliceTypes.TITLE_AND_PARAGRAPH:
        return <TitleAndParagraphSlice key={index} slice={slice} />;
      case sliceTypes.LOGOS:
        return <LogosSlice key={index} slice={slice} />;
      case sliceTypes.CTA_BANNER:
        return <CTABannerSlice key={index} slice={slice} />;
      case sliceTypes.HIGHLIGHTS_WITH_IMAGES:
        return <HighlightsWithImagesSlice key={index} slice={slice} />;
      case sliceTypes.LISTS_IN_GRID:
        return <ListsInGridSlice key={index} slice={slice} />;
      case sliceTypes.QUOTES:
        return <QuotesSlice key={index} slice={slice} />;
      case sliceTypes.SLIDER:
        return <SliderSlice key={index} slice={slice} />;
      case sliceTypes.SLIDER_WITH_BACKGROUND:
        return <SliderWithBackgroundSlice key={index} slice={slice} />;
      case sliceTypes.VIDEO_AND_QUOTE:
        return <VideoAndQuoteSlice key={index} slice={slice} />;
      case sliceTypes.CTA_BANNER_WITH_IMAGE:
        return <CTABannerWithImageSlice key={index} slice={slice} />;
      case sliceTypes.STEPS:
        return <StepsSlice key={index} slice={slice} />;
      case sliceTypes.FEATURES:
        return <FeaturesSlice key={index} slice={slice} />;
      case sliceTypes.FEATURES_IN_GRID:
        return <FeaturesInGridSlice key={index} slice={slice} />;
      case sliceTypes.RICH_TEXT_FIELD:
        return <RichTextFieldSlice key={index} slice={slice} />;
      case sliceTypes.RICH_TEXT_FIELD_GRID:
        return <RichTextFieldGridSlice key={index} slice={slice} />;
      case sliceTypes.BLOG_QUOTE_CALL_OUT:
        return <BlogQuoteCallOutSlice key={index} slice={slice} />;
      case sliceTypes.BLOG_QUOTE_EMBEDDED:
        return <BlogQuoteEmbeddedSlice key={index} slice={slice} />;
      case sliceTypes.BLOG_CALL_OUT:
        return <BlogCallOutSlice key={index} slice={slice} />;
      case sliceTypes.BLOG_CTA_BANNER:
        return <BlogCTABannerSlice key={index} slice={slice} />;
      default:
        return null;
    }
  });
};

export default Slices;
