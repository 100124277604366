import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import {
  fontFG46m,
  fontFG24m,
  fontFG18m,
  fontFG18l,
  fontFG16l
} from "../../styles/fonts";
import arrowRight from "../../assets/images/arrow-right-link.svg";
import SmartLink from "./SmartLink";

const SubTitle = styled.div`
  ${fontFG18l};
  color: ${colors.caption};
  text-transform: capitalize;
  padding-left: 96px;
  position: relative;
  line-height: 21px;
  &:before {
    content: "";
    display: block;
    width: 64px;
    height: 2px;
    background-color: ${colors.caption};
    position: absolute;
    top: 49%;
    left: 0;
  }
`
const Title = styled.h2`
  ${fontFG46m};
`;

const Icon = styled.div`
  img {
    height: 50px;
    width: 50px;
  }
`
const FeatureTitle = styled.h5`
  ${fontFG24m};
`;
const FeatureDescription = styled.h6`
  ${fontFG16l};
`;
const TextWithArrow = styled.p`
  margin-top: auto;
  margin-bottom: 0;
  ${fontFG18m};
  display: inline-flex;
  align-items: center;
  color: ${colors.link};
  &:after {
    transition: all 300ms ease-out;
    content: "";
    width: 16px;
    height: 16px;
    mask: url(${arrowRight});
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: ${colors.link};
    margin-left: 16px;
  }
`;
const Feature = styled(SmartLink)`
  display: flex;
  flex-direction: column;
  ${Icon} {
    margin-bottom: 24px;
  }
  ${FeatureDescription} {
    margin-bottom: 24px;
  }
  &:hover {
    ${FeatureTitle} {
      text-decoration: underline;
    }
    ${TextWithArrow}:after {
      margin-left: 24px;
    }
  }
`;
const Features = styled.div`
  ${Feature} {
    margin-bottom: 48px;
  }
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 32px;
  }
`;
const Container = styled(Grid)`
  margin: 68px 0 12px;

  ${SubTitle} {
    margin-bottom: 24px;
  }
  ${Title} {
    margin-bottom: 48px;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${SubTitle} {
      grid-column: 2 /span 12;
    }
    ${Title} {
      grid-column: 2 / span 12;
    }
  }
`;
export default class FeaturesInGridSlice extends React.Component {
  // onFeatureClick = (featureTitle) => {
  //   window.analytics.track(`Clicked ${featureTitle}`, { label: "More Features Slice" });
  // }

  render() {
    const { slice } = this.props;
    return (
      <Container>
        {slice.primary.sub_title && <SubTitle>{slice.primary.sub_title}</SubTitle>}
        {slice.primary.title && <Title>{slice.primary.title}</Title>}
        <Features>
          {slice.items.map((item, index) => (
            <Feature
              key={index}
              href={item.link_url}
              // onClick={() => this.onFeatureClick(item.feature_title)}
            >
              {item.icon.url &&
                <Icon>
                  <img loading={"lazy"} src={item.icon.url} alt={item.icon.alt} />
                </Icon>
              }
              <FeatureTitle>{item.feature_title}</FeatureTitle>
              <FeatureDescription>{item.feature_description}</FeatureDescription>
              {item.link_text &&
                <TextWithArrow>
                  {item.link_text}
                </TextWithArrow>
              }
            </Feature>
          ))}
        </Features>
      </Container>
    );
  }
}
