import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import { fontFG20l, fontFG38m } from "../../styles/fonts";
import colors from "../../styles/colors";
import Grid from "../../styles/Grid";
import Button from "./CTAButton";
import HubspotFormModal from "./HubspotFormModal";
import { withContext } from "./withContext";
import { appendUtmContentToUrl } from "../utilities/utmUtils";

const MobileButton = styled(Button)``;
const DesktopButton = styled(Button)``;
const CTAButtons = styled.div`
  width: 100%;
  margin-top: 26px;
  text-align: right;
  ${MobileButton} {
    width: 100%;
  }
  ${DesktopButton} {
    display: none;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: auto;
    margin-top: 0;
    ${MobileButton} {
      display: none;
    }
    ${DesktopButton} {
      &:first-child {
        margin-right: 24px;
      }
      display: inline-block;
    }
  }
`;
const CTAText = styled.div``;
const Title = styled.h3`
  ${fontFG38m};
  color: ${colors.white};
  margin-bottom: 16px;
`;
const Description = styled.h6`
  ${fontFG20l};
  color: ${colors.white};
`;
const Wrapper = styled.div`
  margin: 40px 0;
  padding: 40px;
  background-color: ${colors.darkBlue};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${CTAText} {
    flex: 0 1 50%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 50px;
    flex-direction: row;
  }
`;
const Container = styled(Grid)``;

class CTABannerSlice extends React.Component {
  state = {
    hubspotFormModalVisible: false
  };

  openHubspotFormModal = () => {
    this.setState({ hubspotFormModalVisible: true });
  };

  onCancelModal = () => {
    this.setState({ hubspotFormModalVisible: false });
  };

  // onButtonClick = buttonText => {
  //   window.analytics.track(`Clicked ${buttonText}`, { label: "CTA Banner" });
  // };

  render() {
    const {
      slice,
      context: { utmContent },
    } = this.props;
    const { primary } = slice;
    const { hubspotFormModalVisible } = this.state;

    let url = primary.cta_button_url;
    if (url && utmContent) {
      url = appendUtmContentToUrl(url, utmContent)
    }

    return (
      <Container>
        <Wrapper>
          <CTAText>
            <Title>{primary.title}</Title>
            <Description>{primary.description}</Description>
          </CTAText>
          <CTAButtons>
            {primary.cta_desktop_primary_button_text &&
              <DesktopButton
                target="_blank"
                href={url || null}
                onClick={() => {
                  // this.onButtonClick(primary.cta_desktop_primary_button_text);
                  !url && this.openHubspotFormModal();
                }}
              >
                {primary.cta_desktop_primary_button_text}
              </DesktopButton>
            }
            {primary.cta_desktop_secondary_button_text &&
              <DesktopButton
                type="outline"
                onClick={() => {
                  // this.onButtonClick(primary.cta_desktop_secondary_button_text);
                  this.openHubspotFormModal();
                }}
              >
                {primary.cta_desktop_secondary_button_text}
              </DesktopButton>
            }
            {primary.cta_mobile_primary_button_text &&
              <MobileButton
                target="_blank"
                href={url || null}
                onClick={() => {
                  // this.onButtonClick(primary.cta_mobile_primary_button_text);
                  !url && this.openHubspotFormModal();
                }}
              >
                {primary.cta_mobile_primary_button_text}
              </MobileButton>
            }
          </CTAButtons>
        </Wrapper>
        <HubspotFormModal
          targetId="cta_slice_hubspot_form"
          formId={primary.cta_hubspot_form_id}
          visible={hubspotFormModalVisible}
          onCancel={this.onCancelModal}
        />
      </Container>
    );
  }
}
export default withContext(CTABannerSlice);
