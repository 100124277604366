import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fontFG20l, fontFG46m, fontFG38m, fontFG16m } from "../../styles/fonts";
import colors from "../../styles/colors";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import ArrowLink from "./ArrowLink";

const Title = styled.h2`
  ${fontFG46m};
  margin-bottom: 16px;
`;
const Description = styled.h6`
  ${fontFG20l};
  margin-bottom: 24px;
`;
const CardTitle = styled.h3`
  ${fontFG38m};
  margin-bottom: 8px;
`;
const Text = styled.div`
  text-align: left;
`;
const CardBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 100%;
    margin: 12px 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    img {
      width: 50%;
      margin: 0 32px 0 0;
    }
  }
`;
const Card = styled.div``;
const CustomSlider = styled(Slider)`
  padding-bottom: 24px;
  @media (min-width: ${breakpoints.lg}) {
    display: flex !important;
    flex-direction: column;
    .slick-list {
      order: 2;
    }
    .slick-dots {
      width: 80%;
      margin: 0 auto;
      padding: 80px 0;
      order: 1;
      position: relative;
      bottom: initial;
      display: flex !important;
      justify-content: space-around;
      li {
        width: 300px;
        height: 100%;
      }
      @media (min-width: ${breakpoints.lg}) {
        li {
          width: 30%;
          ::after {
            content: "";
            position: absolute;
            top: 1em;
            width: 110%;
            z-index: -1;
            border-top: 2px dashed ${colors.gray4};
          }
          :last-child ::after {
            display: none;
          }
        }
      }
    }
  }
`;

const PageTitle = styled.div`
  ${fontFG16m};
  margin-top: 16px;
`;
const OuterCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
const InnerCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
const RadioButton = styled.div`
  display: inline-block;
  margin: 0 auto;
  ${OuterCircle} {
    position: relative;
    box-sizing: content-box;
  }
  ${InnerCircle} {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;
const Paginator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props) =>
    props.active ? colors.link : colors.disabled};

  @media (min-width: ${breakpoints.lg}) {
    width: auto;
    height: auto;
    background-color: transparent;
    ${OuterCircle} {
      background: ${(props) => (props.active ? colors.green : colors.white)};
      border: 1px solid
        ${(props) => (props.active ? colors.green : colors.disabled)};
    }
    ${InnerCircle} {
      background: ${(props) => (props.active ? colors.white : colors.disabled)};
    }
    ${PageTitle} {
      color: ${(props) => (props.active ? colors.green : colors.disabled)};
    }
  }
`;

const Container = styled(Grid)`
  text-align: center;
  padding: 40px 0;
  @media (min-width: ${breakpoints.lg}) {
    ${Description} {
      grid-column: 4 / span 8;
    }
  }
`;

const SliderSlice = ({ slice }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagination = slice.items.map((item) => item.section_title);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (page) => (
      <Paginator active={currentPage === page}>
        <RadioButton>
          <OuterCircle>
            <InnerCircle />
          </OuterCircle>
        </RadioButton>
        <PageTitle>{pagination[page]}</PageTitle>
      </Paginator>
    ),
    beforeChange: (currentPage, nextPage) => setCurrentPage(nextPage),
    // afterChange: () => window.analytics.track("Clicked Feature Slider"),
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          customPaging: (page) => <Paginator active={currentPage === page} />,
        },
      },
    ],
  };
  return (
    <Container>
      <Title>{slice.primary.title}</Title>
      <Description>{slice.primary.description}</Description>
      <CustomSlider {...settings}>
        {slice.items.map((item, index) => (
          <Card key={index}>
            <CardBody>
              <img loading={"lazy"} src={item.image.url} alt={item.image.alt} />
              <Text>
                <CardTitle>{item.section_main_description}</CardTitle>
                <Description>{item.section_sub_description}</Description>
                {item.link_text && (
                  <ArrowLink href={item.link_url}>{item.link_text}</ArrowLink>
                )}
              </Text>
            </CardBody>
          </Card>
        ))}
      </CustomSlider>
    </Container>
  );
};

export default SliderSlice;
