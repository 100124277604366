import React from "react";
import styled from "styled-components";
import { Icon } from "antd";
import { fontFG46m, fontFG20l } from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import Grid from "../../styles/Grid";

const Title = styled.h1`
  margin-bottom: 24px;
`;
const Subtitle = styled.h6`
  margin-bottom: 48px;
`;
const BulletPoint = styled.span`
  ${fontFG20l};
  margin-left: 22px;
`;
const Item = styled.li`
  margin-bottom: 16px;
  display: flex;
  break-inside: avoid-column;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 32px;
  }
`;
const Section = styled.ul`
  columns: 1;
  text-align: left;
  padding-top: 24px;
  @media (min-width: ${breakpoints.lg}) {
    columns: 2;
  }
`;
const Highlights = styled(Grid)`
  text-align: center;
  ${Title} {
    ${fontFG46m};
  }
  ${Subtitle} {
    ${fontFG20l};
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 60px 0;
    ${Title} {
      max-width: 770px;
      margin: 24px auto;
    }
    ${Subtitle} {
      max-width: 770px;
      margin: 24px auto;
    }
  }
`;

const HighlightsSlice = ({ slice }) => (
  <Highlights>
    <Title>{slice.primary.section_title}</Title>
    <Subtitle>{slice.primary.section_subtitle}</Subtitle>
    <Section>
      {slice.items.map((item, index) => (
        <Item key={index}>
          <Icon
            type="check-circle"
            theme="twoTone"
            twoToneColor={colors.green}
            style={{ marginTop: "8px" }}
          />
          <BulletPoint>{item.bullet_point}</BulletPoint>
        </Item>
      ))}
    </Section>
  </Highlights>
);

export default HighlightsSlice;
