import React from "react";
import { Helmet } from "react-helmet";

// Video thumbnail provided by Wistia

class WistiaVideo extends React.Component {
  render() {
    const { videoId, popover = false } = this.props;

    return (
      <>
        <Helmet>
          <script
            src={`https://fast.wistia.com/embed/medias/${videoId}.jsonp`}
            async
          />
          <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
        </Helmet>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
          <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
            <div class="wistia_embed wistia_async_${videoId} popover=${popover} videoFoam=true" style="height:100%;position:relative;width:100%">
              &nbsp;
            </div>
          </div>
        </div>`
          }}
        />
      </>
    );
  }
}

export default WistiaVideo;
