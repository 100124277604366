import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { fontR14r, fontFG24m, fontFG46m, fontFG20l } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Button from "../shared/CTAButton";
import RichTextWrapper from "./RichText";
import HubspotFormModal from "./HubspotFormModal";
import { withContext } from "./withContext";
import Grid from "../../styles/Grid";
import { appendUtmContentToUrl } from "../utilities/utmUtils";

const MobileButton = styled(Button)``;
const DesktopButton = styled(Button)``;
const CTAButtons = styled.div`
  margin: 0 0 40px 0;
  ${MobileButton} {
    width: 100%;
  }
  ${DesktopButton} {
    display: none;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 20px 0 0 0;
    ${MobileButton} {
      display: none;
    }
    ${DesktopButton} {
      display: inline-block;
    }
  }
`;

const Title = styled.h2`
  ${fontFG46m};
`;
const Description = styled.h6`
  ${fontFG20l};
  margin: 24px 0;
`;
const Header = styled.div``;

const ItemTitle = styled.h5`
  ${fontFG24m};
  margin: 16px 0 12px 0;
`;

const ItemDescription = styled.div`
  ${fontR14r};
  li {
    margin-bottom: 0;
  }
`;
const ListItem = styled.div`
  margin-bottom: 16px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 18px;
  }
`;
const Lists = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  margin-bottom: 24px;
`;
const TextContainer = styled.div``;
const Image = styled.div`
  img {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
`;
const Container = styled(Grid)`
  padding: 40px 0;
  margin: 20px 0;
  ${TextContainer} {
    grid-column: 2 / span 4;
  }
  ${Image} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    grid-column-gap: 30px;
    ${TextContainer} {
      grid-column: 2 / span 6;
    }
    ${Image} {
      grid-column: 8 / span 6;
    }
  }
`;

class ListsInGridSlice extends React.Component {
  state = {
    bookDemoModalOpen: false,
  };

  openBookDemoModal = () => {
    this.setState({ bookDemoModalOpen: true });
  };

  onCancelModal = () => {
    this.setState({ bookDemoModalOpen: false });
  };

  // onButtonClick = buttonText => {
  //   window.analytics.track(`Clicked ${buttonText}`, {
  //     label: "Lists In Grid"
  //   });
  // }

  render() {
    const {
      slice,
      context: { utmContent },
    } = this.props;
    const { bookDemoModalOpen } = this.state;

    let url = slice.primary.lists_button_url;
    if (url && utmContent) {
      url = appendUtmContentToUrl(url, utmContent);
    }

    return (
      <Container>
        <TextContainer>
          <Header>
            <Title>{slice.primary.title}</Title>
            <Description>{slice.primary.description}</Description>
          </Header>
          <Lists>
            {slice.items.map((item, index) => (
              <ListItem key={index}>
                {item.title && <ItemTitle>{item.title}</ItemTitle>}
                <ItemDescription>
                  <RichTextWrapper>
                    {RichText.render(item.paragraph.raw)}
                  </RichTextWrapper>
                </ItemDescription>
              </ListItem>
            ))}
          </Lists>
          <CTAButtons>
            {slice.primary.lists_desktop_button_text && (
              <DesktopButton
                target="_blank"
                href={url || null}
                onClick={() => {
                  // this.onButtonClick(slice.primary.lists_desktop_button_text);
                  !url && this.openBookDemoModal();
                }}
              >
                {slice.primary.lists_desktop_button_text}
              </DesktopButton>
            )}
            {slice.primary.lists_mobile_button_text && (
              <MobileButton
                target="_blank"
                href={url || null}
                onClick={() => {
                  // this.onButtonClick(slice.primary.lists_mobile_button_text);
                  !url && this.openBookDemoModal();
                }}
              >
                {slice.primary.lists_mobile_button_text}
              </MobileButton>
            )}
          </CTAButtons>
        </TextContainer>
        <Image>
          <img
            loading={"lazy"}
            src={slice.primary.image.url}
            alt={slice.primary.image.alt}
          />
        </Image>
        <HubspotFormModal
          targetId="lists_slice_hubspot_form"
          formId={slice.primary.lists_hubspot_form_id}
          visible={bookDemoModalOpen}
          onCancel={this.onCancelModal}
        />
      </Container>
    );
  }
}
export default withContext(ListsInGridSlice);
