import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import breakpoints from "../../styles/breakpoints";
import { fontH1, fontH2, fontH6Light } from "../../styles/fonts";
import colors from "../../styles/colors";

const Header = styled.h2`
  ${fontH2};
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 16px;
  }
`;

const SubHeader = styled.h6`
  ${fontH6Light};
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 56px;
  }
`;

const Title = styled.p`
  ${fontH1};
  margin-bottom: 0;
`;

const Description = styled.p`
  ${fontH6Light};
  color: ${colors.body};
  margin-bottom: 16px;
`;

const BottomBorder = styled.div`
  height: 100%;
  text-align: center;
  border-bottom: 6px solid ${colors.green};
`;
const Column = styled(Col)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.md}) {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;
const Container = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    margin: 68px 0;
  }
`;

const getCallOutContent = (items) => {
  const colCount = items.length;
  let colSpan;
  switch (colCount) {
    case 2:
      colSpan = 12;
      break;
    case 3:
      colSpan = 8;
      break;
    default:
      colSpan = 24;
      break;
  }

  const content = items.map((item) => {
    return (
      <Column xs={24} md={colSpan}>
        <BottomBorder>
          <Title>{item.call_out_title}</Title>
          <Description>{item.call_out_description}</Description>
        </BottomBorder>
      </Column>
    );
  });

  return (
    <Row type="flex" gutter={32}>
      {content}
    </Row>
  );
};

const BlogCallOutSlice = ({ slice }) => {
  const callOutContent = getCallOutContent(slice.items);
  return (
    <Container>
      <Header>{slice.primary.call_out_header}</Header>
      <SubHeader>{slice.primary.call_out_sub_header}</SubHeader>
      {callOutContent}
    </Container>
  );
};

export default BlogCallOutSlice;
