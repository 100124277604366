import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import { fontFG20l, fontFG46m } from "../../styles/fonts";
import colors from "../../styles/colors";
import Grid from "../../styles/Grid";
import Button from "./CTAButton";

const DesktopButton = styled(Button)``;
const CTAText = styled.div``;
const Title = styled.h2`
  ${fontFG46m};
  color: ${colors.white};
`;
const ColoredTitle = styled.h2`
  ${fontFG46m};
  color: ${colors.green};
`;
const Description = styled.h6`
  ${fontFG20l};
  color: ${colors.white};
  margin: 8px 0 28px 0;
`;
const Container = styled(Grid)`
  margin: 40px 0;
  padding: 80px 0;
  background-color: ${colors.darkBlue};
  text-align: center;
  ${CTAText} {
    width: 100%;
  }
  ${DesktopButton} {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 100px 0;
    text-align: left;
    background-image: url(${(props) => props.imgUrl});
    background-size: cover;
    ${CTAText} {
      width: 50%;
    }
    ${DesktopButton} {
      width: auto;
    }
  }
`;

const CTABannerWithImageSlice = ({ slice }) => (
  <Container imgUrl={slice.primary.background_image.url}>
    <CTAText>
      <Title>{slice.primary.title}</Title>
      <ColoredTitle>{slice.primary.title_colored}</ColoredTitle>
      <Description>{slice.primary.description}</Description>
      <DesktopButton
        target="_blank"
        href={slice.primary.button_url}
        // onClick={() =>
        //   window.analytics.track(`Clicked ${slice.primary.button_text}`, {
        //     label: "CTA Banner With Image Slice"
        //   })
        // }
      >
        {slice.primary.button_text}
      </DesktopButton>
    </CTAText>
  </Container>
);

export default CTABannerWithImageSlice;
