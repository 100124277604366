import React from "react";
import styled from "styled-components";
import { fontH4, fontH6 } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import QuoteIcon from "../../assets/images/quote-mark.svg";

const Quote = styled.img`
  display: block;
  width: 80px;
  height: 68px;
  margin-bottom: 40px;
`;

const Content = styled.h4`
  ${fontH4};
  margin-bottom: 40px;
`;

const Name = styled.h6`
  ${fontH6};
  color: ${colors.body};
  margin-bottom: 0;
`;

const Position = styled.h6`
  ${fontH6};
  color: ${colors.green};
`;

const Container = styled.div`
  margin: 40px 0;
  @media (min-width: ${breakpoints.md}) {
    margin: 68px 0;
    padding: 0px 60px;
  }
`;

const BlogQuoteCallOutSlice = ({ slice }) => {
  return (
    <Container>
      <Quote src={QuoteIcon} alt="quote mark" />
      <Content>{slice.primary.quote}</Content>
      <Name>{slice.primary.name}</Name>
      <Position>{slice.primary.position}</Position>
    </Container>
  );
};

export default BlogQuoteCallOutSlice;
