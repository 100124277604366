import React from "react";
import styled from "styled-components";
import { fontFG20m, fontFG24l } from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "../../styles/Grid";
import QuoteIcon from "../../assets/images/quote.svg";
import WistiaVideo from "./WistiaVideo";

const Quote = styled.div`
  ${fontFG24l};
  margin: 40px 0 24px 0;
`;
const Name = styled.div`
  ${fontFG20m};
`;
const JobPosition = styled.div`
  ${fontFG20m};
  color: ${colors.green};
`;

const Card = styled.div`
  margin: auto 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 36px;
    height: 36px;
    top: 0;
    background: url(${QuoteIcon}) no-repeat;
    background-size: 22px;
  }
`;

const VideoWrapper = styled.div`
  margin: auto 0;
`;

const Container = styled(Grid)`
  padding: 40px 0;

  ${VideoWrapper} {
    order: 2;
    padding-top: 40px;
    grid-column: 2 / span 4;
  }
  ${Card} {
    order: 1;
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${VideoWrapper} {
      order: 1;
      padding: 0;
      grid-column: 2 / span 5;
    }
    ${Card} {
      grid-column: 7 / span 7;
    }
  }
`;

const VideoAndQuoteSlice = ({ slice }) => (
  <Container>
    <VideoWrapper>
      <WistiaVideo videoId={slice.primary.wistia_video_id} />
    </VideoWrapper>
    <Card>
      <Quote>{slice.primary.quote}</Quote>
      <Name>{slice.primary.name}</Name>
      <JobPosition>{slice.primary.job_position}</JobPosition>
    </Card>
  </Container>
);

export default VideoAndQuoteSlice;
