import React from "react";
import styled from "styled-components";
import { fontH4, fontH4Light } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";

const Content = styled.h4`
  ${fontH4};
  margin-bottom: 40px;
`;

const AuthorDescription = styled.h6`
  ${fontH4Light};
  margin-bottom: 24px;
`;

const BorderLeft = styled.div`
  border-left: 8px solid ${colors.green};
  padding-left: 32px;
`;

const Container = styled.div`
  margin: 40px 0;
  @media (min-width: ${breakpoints.md}) {
    margin: 68px 0;
    padding: 0px 60px;
  }
`;

const BlogQuoteEmbeddedSlice = ({ slice }) => {
  return (
    <Container>
      <BorderLeft>
        <AuthorDescription>
          {slice.primary.author_description}
        </AuthorDescription>
        <Content>{slice.primary.quote}</Content>
      </BorderLeft>
    </Container>
  );
};

export default BlogQuoteEmbeddedSlice;
