import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../styles/breakpoints";
import { fontFG30m, fontFG20l } from "../../styles/fonts";
import Grid from "../../styles/Grid";

const Title = styled.h4`
  ${fontFG30m};
  margin: 12px;
`;
const Subtitle = styled.h6`
  ${fontFG20l};
  margin: 12px;
`;
const Logo = styled.li`
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const Logos = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  ${Logo} {
    width: ${(1 / 3) * 100}%;
    padding: 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Logo} {
      width: ${(1 / 7) * 100}%;
    }
  }
`;
const Container = styled(Grid)`
  padding: 40px 0;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  ${Title} {
    grid-column: 2 / span 4;
  }
  ${Subtitle} {
    grid-column: 2 / span 4;
  }
  ${Logos} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      grid-column: 4 / span 8;
    }
    ${Subtitle} {
      grid-column: 3 / span 10;
    }
    ${Logos} {
      grid-column: 2 / span 12;
    }
  }
`;

const LogosSlice = ({ slice }) => (
  <Container backgroundColor={slice.primary.background_color}>
    <Title>{slice.primary.title}</Title>
    <Subtitle>{slice.primary.description}</Subtitle>
    <Logos>
      {slice.items.map((item, index) => {
        const logoImageData = getImage(item.logo);
        if (!logoImageData) return null;
        return (
          <Logo key={index}>
            <GatsbyImage image={logoImageData} alt={item.logo.alt || ""} />
          </Logo>
        );
      })}
    </Logos>
  </Container>
);

export default LogosSlice;
