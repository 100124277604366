import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import { fontR14r, fontR16r, fontFG24m, fontFG46m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import Button from "../shared/CTAButton";
import Grid from "../../styles/Grid";

const DesktopButton = styled(Button)`
  display: none;
  @media (min-width: ${breakpoints.lg}) {
    display: inline-block;
    margin: 24px 0 0 32px;
  }
`;
const CTAButton = styled.div``;

const SectionTitle = styled.h5`
  color: ${colors.white};
  ${fontFG24m};
`;

const SectionDescription = styled.div`
  color: ${colors.white};
  ${fontR16r};
  @media (min-width: ${breakpoints.lg}) {
    ${fontR14r};
  }
`;

const Text = styled.div``;
const Image = styled.div`
  img {
    width: 100%;
  }
`;
const Section = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Image} {
    flex: 0 0 20%;
  }
  ${Text} {
    flex: 0 0 75%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 32px;
  }
`;
const Sections = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${breakpoints.lg}) {
    padding: 24px;
  }
`;

const MainImage = styled.div`
  img {
    width: 100%;
  }
`;
const Title = styled.h2`
  ${fontFG46m};
  color: ${colors.white};
  margin-bottom: 40px;
  text-align: center;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${MainImage} {
    padding: 24px;
    flex: 0 0 50%;
  }
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;
const Wrapper = styled.div`
  padding: 48px 24px;
  background-color: ${colors.brandBlue};
  border-radius: 6px;
`;
const Container = styled(Grid)`
  padding: 40px 0;
`;

const HighlightsWithImageSlice = ({ slice }) => {
  return (
    <Container>
      <Wrapper>
        <Title>{slice.primary.title}</Title>
        <Body>
          <MainImage>
            <img
              loading={"lazy"}
              src={slice.primary.image.url}
              alt={slice.primary.image.alt}
            />
          </MainImage>
          <Sections>
            {slice.items.map((item, index) => (
              <Section key={index}>
                <Image>
                  <img
                    loading={"lazy"}
                    src={item.image.url}
                    alt={item.image.alt}
                  />
                </Image>
                <Text>
                  <SectionTitle>{item.title}</SectionTitle>
                  <SectionDescription>{item.description}</SectionDescription>
                </Text>
              </Section>
            ))}
            <CTAButton>
              <DesktopButton
                target="_blank"
                href={slice.primary.highlights_button_url}
                // onClick={() => {
                //   window.analytics.track(
                //     "Highlights With Images - Clicked Hire Now"
                //   );
                // }}
              >
                {slice.primary.highlights_button_text}
              </DesktopButton>
            </CTAButton>
          </Sections>
        </Body>
      </Wrapper>
    </Container>
  );
};

export default HighlightsWithImageSlice;
