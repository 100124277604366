import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import {
  fontBodyRegular,
  fontBodyStrong,
  fontH2,
  fontH4,
  fontH5,
} from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const Wrapper = styled.div`
  margin: 40px 0;
  ${fontBodyRegular};
  font-size: 18px;
  line-height: 26px;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    ${fontH2};
    margin: 68px 0 32px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    ${fontH4};
    margin: 32px 0 16px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    ${fontH5};
    margin: 24px 0 16px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    ${fontBodyStrong};
    font-size: 18px;
    line-height: 26px;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 24px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 1.5em;
  }

  a {
    color: ${colors.link};
  }

  a:visited {
    color: ${colors.blue};
  }

  p.block-img {
    margin: 40px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    display: block;
  }

  // styles for embedded videos
  [data-oembed-type="video"] {
    margin: 40px 0;
    line-height: 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    iframe {
      width: 100%;
      height: 400px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    margin: 68px 0;

    p.block-img {
      margin: 68px 0;
    }
    [data-oembed-type="video"] {
      margin: 68px 0;
    }
  }
`;

const RichTextFieldSlice = ({ slice }) => {
  return <Wrapper>{RichText.render(slice.primary.rich_text_field)}</Wrapper>;
};

export default RichTextFieldSlice;
