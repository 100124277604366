import React from "react";
import styled from "styled-components";
import { fontFG18m } from "../../styles/fonts";
import colors from "../../styles/colors";
import SmartLink from "./SmartLink";
import arrowRight from "../../assets/images/arrow-right-link.svg";

const CustomLink = styled(SmartLink)`
  ${fontFG18m};
  display: inline-flex;
  align-items: center;
  color: ${colors.link};
  &:after {
    transition: all 300ms ease-out;
    content: "";
    width: 16px;
    height: 16px;
    mask: url(${arrowRight});
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: ${colors.link};
    margin-left: 16px;
  }

  &:hover,
  &:active {
    color: ${colors.link};
    &:after {
      margin-left: 24px;
    }
  }
`;

const ArrowLink = ({ ...props }) => (
  <CustomLink {...props}>{props.children}</CustomLink>
);

export default ArrowLink;
