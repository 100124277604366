import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import { fontFG38m, fontFG46m, fontFG20l } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import ArrowLink from "./ArrowLink";

const Title = styled.h2`
  ${fontFG46m};
  text-align: center;
`;
const StepTitle = styled.h3`
  ${fontFG38m};
`;
const StepBrief = styled.div`
  ${fontFG20l}
`;
const CustomArrowLink = styled(ArrowLink)``;
const StepItemDescription = styled.div`
  ${StepTitle} {
    margin-bottom: 8px;
  }
  ${CustomArrowLink} {
    margin-top: 24px;
  }
`;
const Image = styled.div`
  img {
    width: 100%;
  }
`;
const Step = styled(Grid)`
  align-items: center;
`;
const Steps = styled.div`
  ${Step} {
    margin-bottom: 60px;

    ${StepItemDescription} {
      margin-bottom: 60px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Step} {
      margin-bottom: 90px;

      ${StepItemDescription} {
        padding: 0;
        margin: 0;
      }
    }

    ${Step}:nth-child(even) {
      ${StepItemDescription} {
        grid-row: 1;
        grid-column: 1 / span 7;
      }
      ${Image} {
        grid-row: 1;
        grid-column: 8 / span 7;
      }
    }

    ${Step}:nth-child(odd) {
      ${Image} {
        grid-row: 1;
        grid-column: 1 / span 7;
      }
      ${StepItemDescription} {
        grid-row: 1;
        grid-column: 8 / span 7;
      }
    }
  }
`;

const Container = styled(Grid)`
  margin: 40px 0;

  ${Title} {
    margin-bottom: 60px;
  }

  ${Steps} {
    grid-column: 1 / span 6;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      grid-column: 4 / span 8;
    }

    ${Steps} {
      grid-column: 2 / span 12;
    }
  }
`;

const StepsSlice = ({ slice }) => (
  <Container>
    {slice.primary.title && <Title>{slice.primary.title}</Title>}
    <Steps>
      {slice.items.map((item, index) => (
        <Step key={index}>
          <StepItemDescription>
            <StepTitle>{item.title}</StepTitle>
            <StepBrief>{item.description}</StepBrief>
            {item.link_text && (
              <CustomArrowLink href={item.link_url}>
                {item.link_text}
              </CustomArrowLink>
            )}
          </StepItemDescription>
          <Image>
            <img loading={"lazy"} src={item.image.url} alt={item.image.alt} />
          </Image>
        </Step>
      ))}
    </Steps>
  </Container>
);

export default StepsSlice;
