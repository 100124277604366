import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { fontFG38m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import RichTextWrapper from "./RichText";

const Title = styled.h1`
  ${fontFG38m};
  margin-bottom: 24px;
`;

const Container = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    ${Title} {
      flex: 1 0 40%;
      padding-right: 30px;
    }
  }
`;

const TitleAndParagraphSlice = ({ slice }) => (
  <Container>
    <Title>{slice.primary.section_title}</Title>
    <RichTextWrapper>
      {RichText.render(slice.primary.paragraph)}
    </RichTextWrapper>
  </Container>
);

export default TitleAndParagraphSlice;
