import React from "react";
import styled from "styled-components";
import { fontFG20l, fontFG20m, fontFG24l, fontFG46m } from "../../styles/fonts";
import Slider from "react-slick";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "../../styles/Grid";
import QuoteIcon from "../../assets/images/quote.svg";
import rightArrowMedium from "../../assets/images/arrow-right-medium.svg";

const Title = styled.h2`
  ${fontFG46m};
  margin-bottom: 16px;
`;
const Description = styled.h6`
  ${fontFG20l};
  margin-bottom: 40px;
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 60px;
  }
`;
const Quote = styled.div`
  ${fontFG24l};
  padding: 20px 10px 0 10px;
  margin: 40px 0 24px 0;
  @media (min-width: ${breakpoints.lg}) {
    padding: 26px 16px;
  }
`;
const NameAge = styled.div`
  ${fontFG20m};
`;
const JobPosition = styled.div`
  ${fontFG20m};
  color: ${colors.green};
`;

const Card = styled(Grid)`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    top: 0;
    left: 48%;
    background: url(${QuoteIcon}) no-repeat;
    background-size: 22px;
  }
`;

const CustomSlider = styled(Slider)`
  margin: 0 20px;
  @media (min-width: ${breakpoints.lg}) {
    margin: 0;
    padding: 0 68px;
  }
  .slick-next {
    top: 100%;
    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      mask: url(${rightArrowMedium}) no-repeat;
      background-color: ${colors.darkBlue};
      opacity: 1;
    }
    :hover {
      &:before {
        background-color: ${colors.blue_6};
      }
    }
    @media (min-width: ${breakpoints.lg}) {
      top: 50%;
    }
  }
  .slick-prev {
    top: 100%;
    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
      mask: url(${rightArrowMedium}) no-repeat;
      background-color: ${colors.darkBlue};
      opacity: 1;
    }
    :hover {
      &:before {
        background-color: ${colors.blue_6};
      }
    }
    @media (min-width: ${breakpoints.lg}) {
      top: 50%;
    }
  }
`;

const Container = styled(Grid)`
  margin: 40px 0;
  padding: 40px 0;
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  @media (min-width: ${breakpoints.lg}) {
    ${CustomSlider} {
      grid-column: 2 / span 12;
    }
  }
`;

const QuotesSlice = ({ slice }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Container backgroundColor={slice.primary.background_color}>
      <Title>{slice.primary.title}</Title>
      <Description>{slice.primary.description}</Description>
      <CustomSlider
        {...settings}
        // afterChange={() => {
        //   window.analytics.track("Clicked Testimonials Slider");
        // }}
      >
        {slice.items.map((item, index) => (
          <Card key={index}>
            <Quote>{item.quote}</Quote>
            <NameAge>{item.name_age}</NameAge>
            <JobPosition>{item.job_position}</JobPosition>
          </Card>
        ))}
      </CustomSlider>
    </Container>
  );
};

export default QuotesSlice;
