import React, { useState } from "react";
import styled from "styled-components";
import { fontFG20l, fontFG24m, fontFG46m } from "../../styles/fonts";
import Slider from "react-slick";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CTAButton from "../shared/CTAButton";
import Grid from "../../styles/Grid";
import routes from "../../routes";

const Title = styled.h2`
  ${fontFG46m};
  color: ${colors.green};
  margin-bottom: 22px;
`;
const MainDescription = styled.h6`
  ${fontFG24m};
  color: ${colors.white};
`;
const SubDescription = styled.div`
  ${fontFG20l};
  color: ${colors.white};
  margin-bottom: 24px;
`;

const CustomButton = styled(CTAButton)``;
const ButtonRow = styled.div`
  ${CustomButton} {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${CustomButton} {
      width: auto;
    }
  }
`;
const CardBody = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const CardGrid = styled(Grid)`
  height: 100%;
  padding-bottom: 56px;
  ${CardBody} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${CardBody} {
      grid-column: 2 / span 6;
    }
  }
`;
const Card = styled.div`
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 38.54%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.backgroundUrl});
`;
const CustomSlider = styled(Slider)`
  display: flex !important;
  justify-content: center;
  .slick-dots {
    bottom: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    .slick-dots {
      display: relative;
      width: auto;
      margin: 0 auto;
      padding: 4px;
      top: 24px;
      bottom: initial;
      border: 1px solid ${colors.green};
      border-radius: 100px;
      li {
        width: auto;
        height: 100%;
        margin: 0;
      }
    }
  }
`;
const Paginator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: ${(props) =>
    props.active ? colors.link : colors.disabled};
  @media (min-width: ${breakpoints.lg}) {
    width: auto;
    height: auto;
    padding: 10px 32px;
    color: ${colors.white};
    background-color: ${(props) =>
      props.active ? colors.green : "transparent"};
  }
`;
const Container = styled.div`
  padding: 40px 0;
`;

const SliderWithBackgroundSlice = ({ slice }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagination = slice.items.map((item) => item.title);
  const settings = {
    dots: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (page) => (
      <Paginator active={currentPage === page}>{pagination[page]}</Paginator>
    ),
    beforeChange: (currentPage, nextPage) => setCurrentPage(nextPage),
    // afterChange: () => window.analytics.track("Clicked Industry Slider"),
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          customPaging: (page) => <Paginator active={currentPage === page} />,
        },
      },
    ],
  };
  return (
    <Container>
      <CustomSlider {...settings}>
        {slice.items.map((item, index) => (
          <Card key={index} backgroundUrl={item.background_image.url}>
            <CardGrid>
              <CardBody>
                <MainDescription>{item.main_description}</MainDescription>
                <Title>{item.title}</Title>
                <SubDescription>{item.sub_description}</SubDescription>
                <ButtonRow>
                  {item.button_link.uid && (
                    <CustomButton to={routes.home + item.button_link.uid}>
                      {item.button_text}
                    </CustomButton>
                  )}
                </ButtonRow>
              </CardBody>
            </CardGrid>
          </Card>
        ))}
      </CustomSlider>
    </Container>
  );
};

export default SliderWithBackgroundSlice;
