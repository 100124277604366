import _ from "lodash";
import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import { fontH3, fontH6Light } from "../../styles/fonts";
import colors from "../../styles/colors";
import Button from "./CTAButton";

const CTAButton = styled(Button)``;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${CTAButton} {
    width: 100%;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    align-items: flex-end;
    ${CTAButton} {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }
`;
const TextWrapper = styled.div`
  margin-bottom: 32px;
`;
const Title = styled.h3`
  ${fontH3};
  color: ${colors.white};
  margin-bottom: 16px;
`;
const Description = styled.h6`
  ${fontH6Light};
  color: ${colors.white};
  margin-bottom: 0;
`;
const Image = styled.div`
  margin-top: 32px;
  img {
    max-width: 300px;
    width: 100%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
  }
`;
const ContentWrapper = styled.div``;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  padding: 30px;
  background-color: ${colors.darkBlue};
  border-radius: 6px;
  ${ContentWrapper} {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
  ${Image} {
    text-align: right;
    align-self: center;
    flex: 0 1 50%;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    padding: 40px 56px;
    margin: 68px 0;
    ${ContentWrapper} {
      text-align: ${props => (props.noImage ? 'center' : 'left')};
      align-items: ${props => (props.noImage ? 'center' : 'flex-start')};
      flex: ${props => (props.noImage ? '0 1 100%' : '0 1 50%')};
    }
    ${Image} {
      padding-left: 24px;
    }
  }
`;

class BlogCTABannerSlice extends React.Component {
  // onButtonClick = buttonText => {
  //   window.analytics.track(`Clicked ${buttonText}`, { label: "Blog CTA Banner" });
  // };

  render() {
    const { slice } = this.props;
    const { primary } = slice;
    const noImage = _.isEmpty(primary.image);

    return (
      <Container noImage={noImage}>
        <ContentWrapper>
          <TextWrapper>
            <Title>{primary.cta_title}</Title>
            <Description>{primary.cta_description}</Description>
          </TextWrapper>
          <ButtonWrapper>
            {primary.primary_button_text &&
              <CTAButton
                target="_blank"
                href={primary.primary_button_url}
                // onClick={() => {
                //   this.onButtonClick(primary.primary_button_text);
                // }}
              >
                {primary.primary_button_text}
              </CTAButton>
            }
            {primary.secondary_button_text &&
              <CTAButton
                type="outline"
                target="_blank"
                href={primary.secondary_button_url}
                // onClick={() => {
                //   this.onButtonClick(primary.secondary_button_text);
                // }}
              >
                {primary.secondary_button_text}
              </CTAButton>
            }
          </ButtonWrapper>
        </ContentWrapper>
        {!noImage && (
          <Image>
            <img loading={"lazy"} src={primary.image.url} alt={primary.image.alt} />
          </Image>
        )}
      </Container>
    );
  }
}
export default BlogCTABannerSlice;
