import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { RichText } from "prismic-reactjs";
import {
  fontBodyRegular,
  fontBodyStrong,
  fontH2,
  fontH4,
  fontH5,
} from "../../styles/fonts";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const Column = styled(Col)`
  &:first-child {
    margin-bottom: ${(props) => (props.width === 4 ? "0" : "40px")};
  }

  @media (min-width: ${breakpoints.md}) {
    &:first-child {
      margin-bottom: 0;
    }

    &:first-child {
      padding-right: ${(props) => (props.width === 4 ? "0" : "20px")};
    }
    &:last-child {
      padding-left: ${(props) => (props.width === 4 ? "0" : "20px")};
    }
  }
`;
const CustomRow = styled(Row)`
  margin: 40px 0;
  &:first-of-type {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin: 68px 0;
  }
`;
const Container = styled.div`
  ${fontBodyRegular};
  font-size: 18px;
  line-height: 26px;

  h2 {
    ${fontH2};
    margin: 68px 0 32px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    ${fontH4};
    margin: 32px 0 16px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    ${fontH5};
    margin: 24px 0 16px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    ${fontBodyStrong};
    font-size: 18px;
    line-height: 26px;
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-left: 24px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 1.5em;
  }

  a {
    color: ${colors.link};
  }

  a:visited {
    color: ${colors.blue};
  }

  p.block-img {
    margin: 40px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
    display: block;
  }

  // styles for embedded videos
  [data-oembed-type="video"] {
    margin: 40px 0;
    line-height: 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    iframe {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    p.block-img {
      margin: 68px 0;
    }
    [data-oembed-type="video"] {
      margin: 68px 0;
    }
  }
`;

// use width value from prismic to specify column structure
const getColSpan = (widthValue) => {
  switch (widthValue) {
    case 4:
      return 24;
    case 3:
      return 16;
    case 2:
      return 12;
    case 1:
      return 8;
    default:
      return 0;
  }
};

const RichTextFieldGridSlice = ({ slice }) => {
  return (
    <Container>
      {slice.items.map(
        (
          {
            rich_text_col_1,
            rich_text_col_1_width,
            rich_text_col_2,
            rich_text_col_2_width,
          },
          index
        ) => {
          return (
            <CustomRow type="flex" key={index}>
              <Column
                xs={24}
                md={getColSpan(rich_text_col_1_width)}
                width={rich_text_col_1_width}
              >
                {RichText.render(rich_text_col_1)}
              </Column>
              <Column
                xs={24}
                md={getColSpan(rich_text_col_2_width)}
                width={rich_text_col_2_width}
              >
                {RichText.render(rich_text_col_2)}
              </Column>
            </CustomRow>
          );
        }
      )}
    </Container>
  );
};

export default RichTextFieldGridSlice;
