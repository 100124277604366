import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import { fontFG38m, fontFG20l, fontFG14l } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import ArrowLink from "./ArrowLink";

const Title = styled.h3`
  ${fontFG38m};
  text-align: center;
`;

const Icon = styled.div`
  margin-right: 8px;
  img {
    height: 50px;
    width: 50px;
  }
`;
const FeatureName = styled.div`
  ${fontFG14l};
  text-transform: uppercase;
`;
const SectionTitle = styled.div`
  display: flex;
  align-items: center;
`;
const FeatureTitle = styled.h3`
  ${fontFG38m};
`;
const FeatureDescription = styled.h6`
  ${fontFG20l};
`;

const CustomArrowLink = styled(ArrowLink)`
  width: 100%;
`;
const FeatureContent = styled.div`
  ${SectionTitle} {
    margin-bottom: 32px;
  }
  ${FeatureTitle} {
    margin-bottom: 8px;
  }
  ${FeatureDescription} {
    margin-bottom: 0;
  }
  ${CustomArrowLink} {
    margin-top: 32px;
  }
`;

const Image = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;

const Feature = styled(Grid)`
  align-items: center;
`;

const Features = styled.div`
  ${Feature} {
    margin-bottom: 60px;

    ${FeatureContent} {
      margin-bottom: 60px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Feature} {
      margin-bottom: 90px;

      ${FeatureContent} {
        padding: 0;
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    ${Feature}:nth-child(even) {
      ${FeatureContent} {
        grid-row: 1;
        grid-column: 2 / span 6;
      }
      ${Image} {
        grid-row: 1;
        grid-column: 8 / span 6;
      }
    }

    ${Feature}:nth-child(odd) {
      ${Image} {
        grid-row: 1;
        grid-column: 2 / span 6;
      }
      ${FeatureContent} {
        grid-row: 1;
        grid-column: 8 / span 6;
      }
    }
  }
`;

const Container = styled(Grid)`
  margin: 80px 0;

  ${Title} {
    margin-bottom: 60px;
  }

  ${Features} {
    grid-column: 1 / span 6;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      grid-column: 4 / span 8;
    }

    ${Features} {
      grid-column: 1 / span 14;
    }
  }
`;

const FeaturesSlice = ({ slice }) => (
  <Container>
    {slice.primary.title && <Title>{slice.primary.title}</Title>}
    <Features>
      {slice.items.map((item, index) => (
        <Feature key={index}>
          <FeatureContent>
            {item.feature_name && (
              <SectionTitle>
                {item.icon && (
                  <Icon>
                    <img
                      loading={"lazy"}
                      src={item.icon.url}
                      alt={item.icon.alt}
                    />
                  </Icon>
                )}
                <FeatureName>{item.feature_name}</FeatureName>
              </SectionTitle>
            )}
            <FeatureTitle>{item.feature_title}</FeatureTitle>
            <FeatureDescription>{item.feature_description}</FeatureDescription>
            {item.link_text && (
              <CustomArrowLink href={item.link_url}>
                {item.link_text}
              </CustomArrowLink>
            )}
          </FeatureContent>
          <Image>
            <img
              loading={"lazy"}
              src={item.image.url}
              alt={item.image.alt}
              width="620"
              height="620"
            />
          </Image>
        </Feature>
      ))}
    </Features>
  </Container>
);

export default FeaturesSlice;
